import React from 'react'

function Input({ type, name, disabled, className, placeholder, onChange, forwardedref, register, ...inputProp }) {
  return (
    (register ? <input
      type={type}
      name={name}
      disabled={disabled}
      className={`h-12 rounded-lg bg-white px-3 w-full outline-none border ${className}`}
      placeholder={placeholder || 'Search ...'}
      onChange={onChange}
      ref={forwardedref}
      {...register(name)}
      {...inputProp}
    /> : <input
      type={type}
      name={name}
      disabled={disabled}
      className={className}
      placeholder={placeholder || 'Search ...'}
      onChange={onChange}
      ref={forwardedref}
      {...inputProp}
    />)
  )
}

export default Input