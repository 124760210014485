import PropTypes from "prop-types";

const Text = ({
    children,
    name,
    type = "bodyStyleDefault",
    color = "text-gray-800",
    fontWeight,
    fontSize,
    ...property
}) => {
    const textStyle = {
        bodyStyleExtraLarge: `!font-Nunito font-bold lg:font-normal  ${
            fontSize ? fontSize : "text-base lg:text-xl "
        }`,
        bodyStyleMedium: `!font-Nunito font-normal  ${
            fontSize ? fontSize : "text-lg"
        }`,
        bodyStyleLarge: `!font-Nunito font-normal ${
            fontSize ? fontSize : "text-sm lg:text-base"
        }`,
        bodyStyleDefault: `!font-Nunito font-normal leading-6 ${
            fontSize ? fontSize : "text-sm"
        }`,
        bodyStyleSmall: `!font-Nunito font-normal ${
            fontSize ? fontSize : "text-xs"
        }`,
        bodyCaption4: `text-base lg:text-2xl ${fontWeight ? fontWeight : "font-bold"}`,
        bodyCaption5: `text-base lg:text-xl ${fontWeight ? fontWeight : "font-semibold"}`,
        bodyCaption6: `text-lg ${fontWeight ? fontWeight : "font-normal"}`,
      
    };

    return (
        <p
            {...property}
            type={type}
            className={`${textStyle[type]} ${property.className} ${color} ${fontWeight}`}
        >
            {children}
        </p>
    );
};

export default Text;

Text.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	enableCopy: PropTypes.boolean
};
